import { parseJwt } from '~/utils/parse-jwt';

import { useToken } from './use-token';

export const useUserInfo = (forcedToken?: string) => {
  const { token } = useToken();

  if (!token && !forcedToken) {
    return { featureFlags: [] };
  }

  return parseJwt(forcedToken || token) || { featureFlags: [] };
};
