import { createContext, type PropsWithChildren, useEffect, useState } from 'react';

import noop from 'lodash/noop';

// Create Context Object
export const TokenContext = createContext<{
  token: string;
  updateToken: (token: string) => void;
  tokenLoading: boolean;
  setTokenLoading: (newVal: boolean) => void;
}>({
  token: '',
  updateToken: noop,
  tokenLoading: false,
  setTokenLoading: noop,
});

// Create a provider for components to consume and subscribe to changes
export const TokenProvider = ({ children, token: initialToken }: PropsWithChildren<{ token: string }>) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string>(initialToken);

  useEffect(() => {
    if (initialToken) {
      setLoading(false);
    }
    setToken(initialToken);
  }, [initialToken]);

  return (
    <TokenContext.Provider
      value={{
        token,
        tokenLoading: loading,
        setTokenLoading: setLoading,
        updateToken: (newToken) => setToken(newToken),
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
